// Fonts 
@import url(http://fonts.googleapis.com/css?family=Montserrat:400,700|Poppins:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:300,400,500,600,700);


html{
  overflow-x: hidden;
}

body {
  line-height: 1.5;
  font-family: $extra-font;
  -webkit-font-smoothing: antialiased;
  font-size: 17px;
  // color: #3a405b;
  color: rgba(0,0,0,0.65);

}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  font-family: $secondary-font;
  font-weight:600;
  color: $black;
}

h1 ,.h1{
  font-size: 2.5rem;
  
}

h2,.h2 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 42px;
}

h3,.h3 {
  font-size: 1.5rem;
}

h4,.h4 {
  font-size: 1.3rem;
  line-height: 30px;
}

h5,.h5 {
  font-size: 1.25rem;
}

h6,.h6 {
  font-size: 1rem;
}


p{
  line-height: 30px;
}