.portflio-item{
	img{

	}

	.portfolio-item-content{
		position: absolute;
		content:"";
		right: 0px;
		bottom: 0px;
		opacity: 0;
		-webkit-transition: all .35s ease;
		   -moz-transition: all .35s ease;
		    -ms-transition: all .35s ease;
		     -o-transition: all .35s ease;
		        transition: all .35s ease;
	}

	&:before{
		position: absolute;
		content:"";
		left: 0px;
		top:0px;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.8);
		opacity: 0;
		-webkit-transition: all .35s ease;
		   -moz-transition: all .35s ease;
		    -ms-transition: all .35s ease;
		     -o-transition: all .35s ease;
		        transition: all .35s ease;
		        overflow: hidden;
	}

	&:hover:before{
		opacity: 1;
	}

	&:hover .portfolio-item-content{
		opacity: 1;
		bottom: 20px;
		right: 30px;
	}

	.overlay-item{
		position: absolute;
		content:"";
		left: 0px;
		top: 0px;
		bottom: 0px;
		right: 0px;
		display: flex;
		align-items: center;
		justify-content:center;
		font-size:80px;
		color: $primary-color;
		opacity: 0;
		-webkit-transition: all .35s ease;
		   -moz-transition: all .35s ease;
		    -ms-transition: all .35s ease;
		     -o-transition: all .35s ease;
		        transition: all .35s ease;
	}

	&:hover .overlay-item{
		opacity: 1;
	}

}