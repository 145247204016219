#navbar{
  background: rgba(34,35,40,1);
  box-shadow: 0 1px 2px rgba(0,0,0,.05);
  li{
    padding-left: 15px;
  }

 .nav-link{
    font-family: $secondary-font;
    font-weight: 500;
    color: $light;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .5px;
    -webkit-transition: all .25s ease;
       -moz-transition: all .25s ease;
        -ms-transition: all .25s ease;
         -o-transition: all .25s ease;
            transition: all .25s ease;
    
    &:hover, &:focus{
      color: $primary-color;
    }
  }


  .btn{
    padding: .5rem 1.5rem;
    color: $light;
  }

}

.header-top{
  background: rgba(34,35,40,1);
  color: #919194;
  border-bottom: 1px solid rgba(255,255,255,.05);

  .header-top-socials {
    border-right: 1px solid rgba(255,255,255,.05);
    padding: 12px 0px;
  }
  
  .header-top-socials a{
    color: #919194;
    margin-right: 15px;
    font-size: 16px;

  }
  .header-top-info{
    color: #919194;
    font-size: 16px;

    a span{
      color: $light;
    }
    a{
      margin-left: 35px;
      color: #919194;
    }
  }
}


.navbar-brand{
  color: $light;
  font-weight: 600;
  letter-spacing: 1px;

  span{
    color: $primary-color;
  }
}

.dropdown-menu{
  visibility: hidden;
  filter: alpha(opacity=0);
  opacity: 0;
  transition:all .2s ease-in, visibility 0s linear .2s, transform .2s linear;
  -webkit-transition: all .2s ease-in, visibility 0s linear .2s, -webkit-transform .2s linear;
  -o-transition: all .2s ease-in, visibility 0s linear .2s, -o-transform .2s linear;
  -ms-transition: all .2s ease-in, visibility 0s linear .2s, -ms-transform .2s linear;
  width: 250px;
  margin-top: 15px;
  padding: 0px;
  border-radius: 0px;
  display: block;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown:hover .dropdown-menu{
    visibility: visible;
    -webkit-transition: all .45s ease 0s;
       -moz-transition: all .45s ease 0s;
        -ms-transition: all .45s ease 0s;
         -o-transition: all .45s ease 0s;
            transition: all .45s ease 0s;
    opacity: 1;
}
.dropdown-item{
  padding: .8rem 1.5rem;
  text-transform: uppercase;
  font-size: 14px;
}

.dropdown-item:hover{
  background: $primary-color;
  color: $light;
}

ul.dropdown-menu li {
    padding-left: 0px!important;
}

