$light: #fff;
$primary-color: rgb(247, 87, 87);
// $primary-color: #13b1cd;
$secondary-color: #f5f8f9;
$event-color: #c54041;
$law-color: #b99769;
$black: #242424;
$border-color:#dedede;
$primary-font:'Montserrat', sans-serif;
$secondary-font:'Poppins', sans-serif;
$extra-font:'Hind', serif;
