
.navbar-toggle .icon-bar {
  background: $primary-color;
}

input[type="email"],input[type="password"],input[type="text"],input[type="tel"]{
  box-shadow:none;
  height:45px;
  outline: none;
  font-size:14px;
  &:focus {
    box-shadow: none;
    border:1px solid $primary-color;
  }
}


.form-control {
  box-shadow: none;
  border-radius: 0;
  &:focus {
    box-shadow:none;
    border:1px solid $primary-color;
  }
}

//

.py-7{
  padding: 7rem 0px;
}

// Button Style

.btn{
  display: inline-block;
  font-size: 14px;
  font-size: 0.8125rem;
  font-weight: 400;
  padding: 1rem 2.5rem;
  text-transform: uppercase;
  border-radius:0;
  @include transition (all, 0.2s, ease);

   &.btn-icon {
    i {
      font-size:16px;
      vertical-align:middle;
      margin-right:5px;
    }
  }

  &:focus{
    outline: 0px;
    border: 0px;
    box-shadow: none;
  }
}

.btn-main {
  background: $primary-color;
  color: $light;
  @include transition (all, 0.2s, ease);
 
  &:hover {
    background: darken( $primary-color, 20% );
    color: $light;
  }
}


.btn-solid-border {
  border:2px solid $primary-color;
  background:transparent;
  color:$black;
  
  &:hover {
    border:2px solid $primary-color;
    background:$primary-color;
  }
}


.btn-transparent {
  @extend .btn-main;
  background:transparent;
  padding:0;
  color:$primary-color;
  &:hover {
    background:transparent;
    color:$primary-color;
  }
}

.btn-large {
  padding:20px 45px;
  &.btn-icon {
    i {
      font-size:16px;
      vertical-align:middle;
      margin-right:5px;
    }
  }
}

.btn-small {
  @extend .btn-main ;
  padding:10px 25px;
  font-size:12px;
}

.btn-round {
  border-radius:4px;
}
.btn-round-full {
  border-radius:50px;
}


.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}


// Background

.bg-gray {
  background:$secondary-color;
}
.bg-primary {
  background:$primary-color;
}
.bg-primary-dark {
  background:darken($primary-color, 10%);
}
.bg-primary-darker {
  background:darken($primary-color, 20%);
}

.bg-dark {
  background:$black;
}


.bg-gradient{
    background-image: linear-gradient(145deg, rgba(19, 177, 205, 0.95) 0%, rgba(152, 119, 234, 0.95) 100%);
    background-repeat: repeat-x;
}


//  Section Title
.section {
  padding:100px 0;
}
.section-sm {
  padding:70px 0;
}

.section-title {
  margin-bottom: 70px;
 
 .title{
  font-size: 50px;
  line-height: 50px;
 }
  p {
      color: #666;
      font-family:$secondary-font;
  }
}


.subtitle {
  color: $primary-color;
  font-size: 14px;
  letter-spacing: 1px;
}




.overly {
  position: relative;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000;
  }
}


.overly-2 {
  position: relative;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
  }
}


.text-color{
  color: $primary-color;
}

.text-black{
  color: $black;
}

.text-color2{
  color: $event-color;
}

.text-color2{
  color: $law-color;
}


.text-sm{
  font-size: 14px;
}
.text-md{
  font-size: 2.25rem;
}
.text-lg{
  font-size:3.75rem; 
}

.no-spacing{
  letter-spacing: 0px
}


/* Links */
a {
  color: $black;
  text-decoration: none;
}

a:focus, a:hover {
  color: $primary-color;
  text-decoration: none;
}

a:focus {
  outline: none;
}


.content-title {
  font-size: 40px;
  line-height: 50px;
}



.page-title{
  padding: 100px 0;
  @extend .overly;
  .block{
    h1{
      color:$light;
    }
    p{
      color:$light;
    }
  }
}


.page-wrapper {
  padding:70px 0;
}

